import React, { useState } from "react";
import api from "../services/api";

export const ProlabContext = React.createContext({});

export const ProlabProvider = ({ children }) => {
  const listPillsProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/learning/categories/${id}/pills-category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createPillProlabApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/learning/pills/insert", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listVideosProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/learning/categories/${id}/list-videos-category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };
  const showVideoProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/learning/videos/${id}/show`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showPillProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`learning/pills/${id}/show`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showPillRandomProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/learning/categories/${id}/show-pill-random`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createVideoProlabApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/learning/videos/insert", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const updateVideoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`learning/videos/${id}/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const updatePillApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`/learning/pills/${id}/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const deletePillApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`learning/pills/${id}/delete`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const deleteVideoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`learning/videos/${id}/delete`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const deleteSecondaryCategoryVideoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`learning/videos/secondary-category/${id}/delete-secondary`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const deleteSecondaryCategoryPillApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`learning/pills/secondary-category/${id}/delete-secondary`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listDocumentsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`documents/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const deleteDocumentApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`documents/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  return (
    <ProlabContext.Provider
      value={{
        listPillsProlabApi,
        createPillProlabApi,
        listVideosProlabApi,
        createVideoProlabApi,
        showPillProlabApi,
        showVideoProlabApi,
        updateVideoApi,
        updatePillApi,
        deleteVideoApi,
        deletePillApi,
        showPillRandomProlabApi,
        deleteSecondaryCategoryVideoApi,
        deleteSecondaryCategoryPillApi,
        listDocumentsApi,
        deleteDocumentApi,
      }}
    >
      {children}
    </ProlabContext.Provider>
  );
};
