import React, { useContext, useState, useEffect } from "react";
import Moment from "react-moment";

import {
  Box,
  Input,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Grid,
  GridItem,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";

export default function DetailsOrderExpert() {
  const { getOrderInfo, sendApproveOrderApi } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [orderDetails, setOrderDetails] = useState();
  const [wordsDone, setWordsDone] = useState();
  const [isFetching, setIsFetching] = useState(false);

  async function getOrderDetails() {
    if (isFetching) return; // If a request is already in progress, skip making a new request
    setIsFetching(true);
    const orderID = localStorage.getItem("orderID");

    try {
      const orderInfoApi = await getOrderInfo(orderID);

      setOrderDetails(orderInfoApi.data?.[0]);
      localStorage.setItem("order", JSON.stringify(orderInfoApi.data?.[0]));
      console.log("ORDER:",orderInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false); // Reset the flag when the request is complete (either success or error)
    }
  }

  const sendToApprove = async () => {
    if(!wordsDone || (wordsDone && wordsDone < 1)){
      toast({
        title: "Quantidade de palavras informada é inválida",
        status: "error",
        isClosable: true,
      });

      return
    }
    const orderID = localStorage.getItem("orderID");
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const data = {
      user_id: userInfo.id,
      order_id: orderID,
      words_done: wordsDone,
    };
    try {
      const newUser = await sendApproveOrderApi(data, orderID);
      if (newUser.status === 200) {
        toast({
          title: "Solicitada Aprovação",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setWordsDone(0)
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }

    onClose();
  };

  useEffect(() => {
    getOrderDetails();

    // Add a throttle to prevent excessive requests (e.g., allow a request every 5 seconds)
    const throttleTimeout = setTimeout(() => {
      getOrderDetails();
    }, 5000); // 5000 milliseconds (5 seconds) delay between requests

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(throttleTimeout);
  }, []);

  return (
    <>
      {orderDetails && (
        <>
          <Box
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={"180px"}
            maxWidth={"100%"}
          >
            <Flex pb={5} borderBottom={"1px solid #D9D9D9"}>
              <Box>Detalhes do serviço</Box>
            </Flex>
            <Grid
              paddingTop={5}
              templateColumns="repeat(2, 1fr)"
              borderBottom={"1px solid  #D9D9D9"}
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Título
                </Text>

                <Text fontSize={"16px"}> {orderDetails.title}</Text>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(4, 1fr)"
              borderBottom={"1px solid  #D9D9D9"}
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  ID
                </Text>

                <Text> {orderDetails.id}</Text>
              </GridItem>

              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Plano
                </Text>
                <Text>
                  {" "}
                  {orderDetails?.plan_id === 1
                    ? "Basic"
                    : orderDetails?.plan_id === 2
                    ? "PRO"
                    : orderDetails?.plan_id === 3
                    ? "FULL"
                    : null}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Tipo de Documento
                </Text>
                <Text pl={3}>{orderDetails.type_document}</Text>
              </GridItem>

              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  FastDelivery
                </Text>
                <Text pl={3}>
                  {orderDetails.fast_delivery === 0 ? "NÃO" : "SIM"}
                </Text>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(2, 1fr)"
              borderBottom={
                orderDetails.description != null ? "1px solid #D9D9D9" : ""
              }
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Idioma origem do documento
                </Text>

                <Text>
                  {orderDetails.language_origin == "pt" ? "Português" : ""}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Idioma final do documento
                </Text>
                <Text pl={3}>
                  {orderDetails.language_final == "en" ? "Inglês" : ""}{" "}
                </Text>
              </GridItem>
            </Grid>
            {orderDetails.description != null ? (
              <Grid>
                <GridItem>
                  <Text color={"#748298"} pb={15}>
                    Descrição
                  </Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<Text pl={3}>${orderDetails.description}</Text>`,
                    }}
                  />
                </GridItem>
              </Grid>
            ) : (
              ""
            )}
            <Button onClick={onOpen}>Solicitar finalização</Button>
          </Box>
          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            mb={35}
            ml={"180px"}
            maxWidth={"100%"}
          >
            <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
              Valor e Prazo
            </Text>
            <Grid templateColumns="repeat(3, 1fr)">
              <>
                <GridItem pt={2}>
                  <Text
                    color={"#748298"}
                    fontSize={"14px"}
                    fontWeight={"light"}
                  >
                    Valor a receber
                  </Text>

                  <Text>
                    R${" "}
                    {orderDetails.historic?.[0].value}
                  </Text>
                </GridItem>
                <GridItem
                  display={"flex"}
                  alignItems={"center"}
                  fontSize={"12px"}
                  pt={5}
                >
                  <>
                    <span style={{ marginRight: "10px" }}>Data de entrega</span>
                    <Moment format="DD-MM-YYYY">
                      {orderDetails.historic?.deadline}
                    </Moment>
                  </>
                </GridItem>
              </>
            </Grid>
            <Box p={5} fontSize={"14px"}>
              <Text> Serviço </Text>
              <Text fontSize={"14px"} fontWeight={"light"} ml={2} mt={0.5}>
                {orderDetails.historic?.[0].approved === 0
                  ? " Pendente de Aprovação"
                  : orderDetails.historic?.[0].approved === 1
                  ? " Aprovado"
                  : orderDetails.historic?.[0].approved === 2
                  ? " Não Aprovado"
                  : ""}
              </Text>
              <Text> Palavras Feitas </Text>
              <Text fontSize={"14px"} fontWeight={"light"} ml={2} mt={0.5}>
                {orderDetails.historic?.[0].words_done}
              </Text>
              <Text> Finalizado </Text>
              <Text fontSize={"14px"} fontWeight={"light"} ml={2} mt={0.5}>
                {orderDetails.historic?.[0].finished_date}
              </Text>
            </Box>
          </Box>

          <Box
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={"180px"}
            maxWidth={"100%"}
            mt={5}
          >
            <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
              {" "}
              Documentos
            </Text>

            <Grid p={5} templateColumns="repeat(3, 1fr)">
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Número de palavras
                </Text>

                <Text pl={3}>{orderDetails.words_number_original}</Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Número de referências
                </Text>

                <Text pl={3}>{orderDetails.total_reference}</Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Quantidade de figuras
                </Text>

                <Text pl={3}>{orderDetails.qty_figure}</Text>
              </GridItem>
            </Grid>
            <Box p={5}>
              <Text color={"#748298"} pt={15} pb={5}>
                Abstract
              </Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<Text pl={3}>${orderDetails.abstract}</Text>`,
                }}
              />
            </Box>
          </Box>
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Finalizar Serviço</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={2}>
              <Text fontWeight={"bold"} mt={2}>
                Como contar quantidade de palavras ?
              </Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <GridItem w="100%">
                  <Text fontWeight={"bold"} mt={2} color={"red"}>
                    NÃO CONTAR{" "}
                  </Text>
                  <UnorderedList color={"red"} fontSize={"12px"}>
                    <ListItem> Nomes de autores </ListItem>
                    <ListItem> Referências</ListItem>
                    <ListItem>
                      Tópicos que o cliente não solicitar tradução/revisão
                    </ListItem>
                  </UnorderedList>
                </GridItem>
                <GridItem w="100%">
                  <Text fontWeight={"bold"} mt={2} color={"green"}>
                    CONTAR{" "}
                  </Text>
                  <UnorderedList color={"green"} fontSize={"12px"}>
                    <ListItem> Titulo</ListItem>
                    <ListItem> Afiliações</ListItem>
                    <ListItem>Abstract + palavras-chave</ListItem>
                    <ListItem>Bullet points/highlights (se aplicável)</ListItem>
                    <ListItem>
                      Texto (Introdução até o nome referencias)
                    </ListItem>
                    <ListItem>Tabelas</ListItem>
                    <ListItem>Palavras dentro das figuras</ListItem>
                    <ListItem>Legendas de figuras</ListItem>
                    <ListItem>Cover letter</ListItem>
                    <ListItem>
                      Elementos pós-textuais (Agradecimentos, Ética,
                      Contribuições dos autores, Financiamento)
                    </ListItem>
                  </UnorderedList>
                </GridItem>
              </Grid>
            </Box>
            <FormControl isRequired>
              <FormLabel>
                Adicione com quantas palavras seu texto ficou
              </FormLabel>
              <Input
                type="number"
                onChange={(e) => setWordsDone(e.target.value)}
                pattern="[0-9]*"
                placeholder="Com quantas palavras seu texto ficou ?"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={sendToApprove}>
              Enviar para aprovação
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Fechar agora
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
