import {
  Avatar,
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Select,
  SimpleGrid,
  Table,
  TableContainer,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineExclamationCircle, AiOutlinePieChart, AiTwotoneInfoCircle } from "react-icons/ai";
import { FaGripLines } from "react-icons/fa6";
import { PiChartLineDownBold, PiChartLineUpBold } from "react-icons/pi";
import { TbHandRock } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { UserContext } from "../../contexts/User";
import Logo from "../../images/avatar.jpg";
export default function Performance() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [expert, setExpert] = useState();
  const [id, setID] = useState();
  const [totalScore, setTotalScore] = useState(null);
  const [categoryScore, setCategoryScore] = useState(null);
  const { getExpertInfoApi } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedServiceCount, setSelectedServiceCount] = useState(0);
  const [typeOrder, setTypeOrder] = useState("general");
  const navigate = useNavigate();
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const handleServiceCountChange = (event) => {
    setSelectedServiceCount(parseInt(event.target.value, 10));
  };

  const formattedDate = new Date(expert?.created_at).toLocaleDateString(
    "pt-BR",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }
  function handleEditService(orderId) {
    localStorage.setItem("orderID", orderId);
    const type = typeOrder === "general" ? "geral" : "edicao-normas";
    if (user.role_id === 1) {
      navigate(`/servico/${orderId}/${type}`);
    } else {
      navigate(`/expert/servico/${orderId}/${type}`);
    }
  }
  function lineBreak(text) {
    const openParenIndex = text.indexOf("(");
    const closeParenIndex = text.indexOf(")");
    if (
      openParenIndex !== -1 &&
      closeParenIndex !== -1 &&
      closeParenIndex > openParenIndex
    ) {
      const mainText = text.slice(0, openParenIndex).trim();
      const parenthesisText = text.slice(openParenIndex + 1, closeParenIndex);
      return (
        <span>
          {mainText}
          <br />
          <Text as="span" fontSize={"12px"} color={"#B1B1B1"}>
            ({parenthesisText})
          </Text>
        </span>
      );
    }
    return text;
  }

  const getCategoryText = (category) => {
    switch (category) {
      case "Pontuação":
        return "Considere as seguintes pontuações usadas de forma inadequada no texto e em números, inclusive em tabelas e figuras: ponto final, exclamação e interrogação, reticências, vírgulas, ponto e vírgula, dois pontos, aspas, apóstrofo, parênteses e colchetes, traço e hífen e barra oblíqua.";
      case "Números":
        return "Considere os seguintes pontos: uso de números por extenso e unidades de medida, espaço entre números e símbolos, consistência no uso de números e unidades de medida, uso correto do “from... to...” e “between...and...”.";
      case "Consistência":
        return "Os termos-chave, incluindo abreviações, são palavras importantes para o correto entendimento pelo leitor, portanto, devem sempre se manter consistentes em todo o texto. Por exemplo, os termos em negrito abaixo são considerados chave e não devem ser modificados por outras palavras no texto, pois podem confundir o leitor. ";
      case "Abreviações":
        return 'Considere: não abreviar a palavra "informed consent form", a não ser que ela seja usada muitas vezes no texto; abreviações no abstract não são consideradas no texto - pense como sessões distintas do manuscrito; abreviam-se as palavras principais do texto que são repetidas várias vezes; a abreviação deve ser feita sempre quando o termo aparece pela primeira vez; depois que se usa a abreviação, somente a abreviação pode ser usada em todo o texto; se uma palavra foi abreviada e ela só foi usada uma vez, não existe motivo para abreviá-la (o Expert deve retirar a abreviação e avisar ao autor); abreviações retiradas do texto também devem ser retiradas da lista de abreviações (caso essa seja incluída no manuscrito).';
      case "Plain English (Reorganização de sentenças, concôrdancia e conexão)":
        return 'Considere: concordância e conexão entre sentenças e parágrafos; modificar orações dentro das sentenças para a leitura ficar mais fluida (exemplo: redução de vírgulas); incluir conectivos para facilitar transição entre sentenças (por exemplo, “furthermore”, “however”, “also”); unir sentenças ou parágrafos que falam do mesmo assunto; separar sentenças ou parágrafos com muitas ideias ou com ideias muito distintas; deixar os "modifying phrases" próximo das palavras que eles modificaram; minimizar o uso de “traços” no texto; retirar ou incluir letras e números indicando sequência sem necessidade (ver exemplo abaixo de números desnecessários na sentença).';
      case "Plain English (Writing zeros e nominalização)":
        return 'O máximo de writing zeros deve ser retirado das sentenças, pois não adiciona nenhuma informação ao texto. Além disso, nominalizações e uso de “there is/are/were/was” induzem a inclusão de writing zeros, portanto, também devem ser evitados. Para entender se um trecho é um writing zero, tente retirá-lo da oração e analise se o entendimento da sentença se mantém o mesmo (se sim, então é muito provável que seja um writing zero). Temos uma lista de writing zeros que devem ser eliminados na planilha “Word choice". Veja também o exemplo abaixo destacando exemplos de writing zeros que devem ser retirados da sentença.';
      case "Capitalização":
        return "Devem ser capitalizadas: palavras que iniciam sentenças; palavras formais (nomes próprios, cidades e países, projetos sociais de governos, organizações, entidades [governo ou privada], nomes de hospitais, departamentos, laboratórios e universidades); resoluções (exemplo: Resolution 466/12); nomes de escalas ou questionários (exemplo: Borg Scale, Maslach Burnout Inventory); as palavras “Table”, “Figure” e “Appendix” se acompanhadas de números (exemplo: Table 1, Figure 4, Appendix 1); bases de dados  (exemplo: MEDLINE/PubMed, Web of Science, EMBASE). Não se capitalizam palavras: após “dois pontos”, após “ponto e vírgula” e entre parênteses. As palavras “informed consent form” e “research ethics committee” NÃO são capitalizadas.";
      case "Termos da área":
        return "Devemos usar somente termos da área do artigo que já foram publicados em outros artigos da área, principalmente quando esses termos são noun strings. Por exemplo, o termo “brushing abrasion” está presente em várias publicações, enquanto que “abrasion by brushing” não é comum. O ponto principal aqui é fazer a busca no PubMed. Pode também pesquisar no Google da seguinte forma: “palavra a ser pesquisada” (pubmed).";
      case "Qualidade Geral":
        return 'Considerando erros como: Modificação do sentido do trecho ou interpretação inadequada do texto original; Uso indevido do do "it is"; Palavras escritas erradas ou não adequadas (ex.: usou "through" ao invés de "using"); Organização do texto; Redundâncias; Uso inapropriado de verbos moldais (exemplo: "can" e "may", "should" e "must", "will" e "would"); Repetição de "we" no texto; Repetição do "sentence opener" já usado no mesmo parágrafo ou em parágrafos próximos; Não traduziu ou não incluiu as abreviações no rodapé das tabelas e legendas de figuras; Uso demasiado ou sem necessidade do "ing"; Preposições incorretas; "Parenthetical phrases" ou "parallel structures" incorretos; Sentenças incompletas.';
      default:
        return "";
    }
  };

  const InfoIcon = ({ category }) => {
    const [showText, setShowText] = useState(false);
    const text = getCategoryText(category);

    return (
      <div style={{ position: "absolute", top: 8, right: 8 }}>
        <AiTwotoneInfoCircle
          size={16}
          onMouseEnter={() => setShowText(true)}
          onMouseLeave={() => setShowText(false)}
        />
        {showText && (
          <div
            style={{
              position: "absolute",
              top: 24,
              right: 0,
              width: 300,
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: 5,
              zIndex: 1000,
            }}
          >
            <p style={{ fontSize: "12px", lineHeight: "16px", margin: 0 }}>
              {text}
            </p>
          </div>
        )}
      </div>
    );
  };

  const getFormatedValue = (value) => {
    if (!value) {
      return "0.00";
    }

    if (typeof value === 'string') {
      return parseFloat(value).toFixed(2);
    }

    if (typeof value === 'number') {
      return value.toFixed(2);
    }

    return value;
  }

  async function getExpertInfo(id) {
    try {
      const response = await getExpertInfoApi(id);
      console.log(response.data?.[0]);
      console.log(response);
      setExpert(response.data?.[0]);
      setTotalScore(response.data?.[0]?.qtyOrdersParticipation);
      setCategoryScore(Object.values(response.data?.[0]?.score?.Categories));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user.role_id === 1) {
      setID(localStorage.getItem("expertID"));
    }
    if (user.role_id === 2) {
      console.log("USER_ID", user.expert.id);
      setID(user.expert.id);
    }
  }, [user.role_id]); // Add an empty dependency array to prevent multiple calls on re-renders

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getExpertInfo(id)
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <Navbar />

      {isLoading && <div style={overlayStyle}>Buscando Informações...</div>}

      {expert && (
        <>
          <Container
            pb={8}
            position={"absolute"}
            top={120}
            ml={{ base: 0, md: 300 }}
            minWidth={{ base: 0, md: "75vw" }}
          >
            <Box
              boxShadow={"0px 8px 16px -10px"}
              borderRadius={"8px"}
              position={"relative"}
            >
              <Box
                backgroundColor={"#2AD67E"}
                minHeight={"100px"}
                borderRadius={"8px 8px 0 0"}
                position={"relative"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box w={"660px"} marginTop={"25px"} p={"25px"}>
                  <Box>
                    <Text fontWeight={500} fontSize={"24px"}>
                      {expert.name}
                    </Text>
                    <Text fontWeight={300} fontSize={"12px"}>
                      <b>Usuário desde:</b> {formattedDate}
                    </Text>
                  </Box>
                </Box>
                <Box
                  position={"absolute"}
                  left={"0"}
                  top={"50%"}
                  transform={"translateX(-50%)"}
                  paddingLeft={"180px"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Avatar w={"95px"} h={"95px"} src={Logo} />
                </Box>
              </Box>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"white"}
                minHeight={"80px"}
                borderRadius={" 0 0 8px 8px"}
              >
                <Box>
                  {/*<Box display={"flex"} alignItems={"center"} marginBottom={"16px"}>
                  <Box
                    backgroundColor={"#04D19421"}
                    marginLeft={5}
                    width={"30px"}
                    height={"30px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"50%"}
                  >
                    <Icon color={"#00FF94"} fontSize={22} textAlign={"center"}>
                      <TbHandRock />
                    </Icon>
                  </Box>
                  <Box display={"inline-flex"} marginLeft={2}>
                    <Text fontSize={"22px"} fontWeight={"600"}>
                      02
                    </Text>
                    <Text
                      fontSize={{ base: "10px", md: "12px" }}
                      lineHeight={1}
                      fontWeight={"300"}
                      marginLeft={2}
                    >
                      Serviços<br></br>
                      Concluídos
                    </Text>
                  </Box>
               </Box>*/}
                  <Box w={"660px"} marginTop={"25px"} p={"2px"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={"10px"}
                    >
                      <Box
                        backgroundColor={"#04D19421"}
                        marginLeft={5}
                        width={"30px"}
                        height={"30px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"50%"}
                        marginBottom={"10px"}
                      >
                        <Icon
                          color={"#00FF94"}
                          fontSize={22}
                          textAlign={"center"}
                        >
                          <TbHandRock />
                        </Icon>
                      </Box>
                      <Box display={"inline-flex"} marginLeft={2}>
                        <Text fontSize={"22px"} fontWeight={"600"}>
                          {expert?.qtyOrdersParticipation}
                        </Text>
                        <Text
                          fontSize={{ base: "10px", md: "12px" }}
                          lineHeight={1}
                          fontWeight={"300"}
                          marginLeft={2}
                        >
                          Serviços<br></br>
                          Concluídos
                        </Text>
                      </Box>

                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          backgroundColor={"#04D19421"}
                          marginLeft={5}
                          width={"30px"}
                          height={"30px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderRadius={"50%"}
                        >
                          <Icon
                            color={"#00FF94"}
                            fontSize={22}
                            textAlign={"center"}
                          >
                            <TbHandRock />
                          </Icon>
                        </Box>
                        <Box display={"inline-flex"} marginLeft={2}>
                          <Text fontSize={"22px"} fontWeight={"600"}>
                            {expert.score.Geral.qtyFeedbacks}
                          </Text>
                          <Text
                            fontSize={{ base: "10px", md: "12px" }}
                            lineHeight={1}
                            fontWeight={"300"}
                            marginLeft={2}
                          >
                            Feedbacks<br></br>
                            Recebidos
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box w={"660px"} marginTop={"10px"} p={"2px"}>
                    <Box display={"flex"} marginBottom={"25px"}>
                      <Box marginRight={"25px"}>
                        {expert.permissions?.length > 0 && (
                          <>
                            {expert.permissions.map((permission, index) => (
                              <Text
                                key={index}
                                textAlign={"center"}
                                backgroundColor={"#04D19414"}
                                borderRadius={"100px"}
                                width={"80px"}
                                p={"5px 0"}
                                marginLeft={"15px"}
                                color={"#2AD67E"}
                              >
                                {permission.expert_role_id === 1
                                  ? "Tradutor"
                                  : permission.expert_role_id === 2
                                  ? "Revisor"
                                  : permission.expert_role_id === 3
                                  ? "Editor"
                                  : permission.expert_role_id === 4
                                  ? "Editor de Normas"
                                  : " "}
                              </Text>
                            ))}
                          </>
                        )}
                        <Text fontWeight={300}>
                          {expert.infos?.profession && (
                            <>Formação: {expert.infos.profession}</>
                          )}
                        </Text>
                      </Box>

                      <Box>
                        <Text fontWeight={300} fontSize={"12px"}>
                          {" "}
                          <b>Dados para contato:</b>
                          <br /> Tel:{expert.phone} <br /> {expert.email}{" "}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex
              backgroundColor={"white"}
              borderRadius={"8px 8px"}
              boxShadow={"0px 8px 16px -10px"}
              marginTop={"10px"}
              width={"100%"}
              //overflowX={"hidden"}
            >
              <Box w={"100%"} pb={8}>
                <Box
                  w={"100%"}
                  display={"flex"}
                  marginTop={"20px"}
                  marginBottom={"20px"}
                  paddingBottom={"20px"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  borderBottom={"1px"}
                  borderColor={"#B1B1B1"}
                >
                  <AiOutlinePieChart color={"#2AD67E"} fontSize={"24px"} />
                  <Text fontWeight={400} fontSize={"20px"}>
                    Desempenho nos serviços
                  </Text>
                  <Text fontWeight={400} fontSize={"16px"} color={"#979797"}>
                    Informações atualizadas com base no seu último serviço na
                    Provatis
                  </Text>
                </Box>

                {totalScore != 0 ? (
                  <>
                    <Box w={"660px"} marginTop={"25px"} p={"20px"}>
                      <Box marginBottom={"20px"} ml={5}>
                        <Text fontWeight={400} fontSize={"16px"}>
                          Selecione o número de serviços para calcular a média:
                        </Text>
                        <Select
                          onChange={handleServiceCountChange}
                          value={selectedServiceCount}
                        >
                          <option value={0}>Todos</option>
                          <option value={5}>5 Serviços</option>
                        </Select>
                        {selectedServiceCount === 5 &&
                          expert?.score.Geral.qtyFeedbacks < 5 && (
                            <Text
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"#979797"}
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <AiOutlineExclamationCircle
                                style={{ marginRight: "5px" }}
                              />
                              Ainda não foram registrados 5 feedbacks
                            </Text>
                          )}
                      </Box>
                      <Text ml={7} fontSize={"24px"}>
                        {" "}
                        Seu score total{" "}
                      </Text>
                      <SimpleGrid columns={4} mb={2} ml={7}>
                        {/*<GridItem marginLeft={"130px"}>
                          <Box
                            display={"flex"}
                            backgroundColor={"#000000"}
                            w={"240px"}
                            h={"120px"}
                            borderRadius={"8px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Box p={8}>
                              <Text
                                fontWeight={400}
                                fontSize={"16px"}
                                color={"#B1B1B1"}
                              >
                                Feedbacks Recebidos
                              </Text>
                              <Text
                                fontWeight={700}
                                fontSize={"40px"}
                                color={"#FFFFFF"}
                              >
                                {expert?.qtyFeedbacks}
                              </Text>
                            </Box>
                          </Box>
                                  </GridItem> */}

                        <Grid
                          css={{
                            "@media screen and (max-width: 1400px)": {
                              gridTemplateColumns: "repeat(3, 1fr)",
                            },
                            "@media screen and (min-width: 1401px)": {
                              gridTemplateColumns: "repeat(4, 1fr)",
                            },
                          }}
                          width="100%"
                          gap={2}
                        >
                          <GridItem>
                            <Box
                              display={"flex"}
                              backgroundColor={"#000000"}
                              w={"250px"}
                              h={"140px"}
                              borderRadius={"8px"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              {selectedServiceCount === 0 && (
                                <Box p={8}>
                                  <Text
                                    fontWeight={400}
                                    fontSize={"16px"}
                                    color={"#B1B1B1"}
                                  >
                                    Média do score em todos os Serviços
                                  </Text>
                                  <Text
                                    fontWeight={700}
                                    fontSize={"40px"}
                                    color={"#FFFFFF"}
                                  ></Text>
                                  <Box display="flex" alignItems="center">
                                    <Text
                                      fontWeight={700}
                                      fontSize={"40px"}
                                      color={"#FFFFFF"}
                                    >
                                      {expert.score.Geral.average_absolute}
                                    </Text>
                                    {expert?.score.Geral.average_evolution > 0 && (
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        marginLeft="16px"
                                        style={{
                                          backgroundColor: "#04D19414",
                                          padding: "0 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            marginLeft: "16px",
                                            color: "#04D194",
                                          }}
                                        >
                                          +{expert?.score.Geral.average_evolution}
                                        </Text>
                                        <PiChartLineUpBold
                                          style={{
                                            marginLeft: "8px",
                                            color: "#04D194",
                                          }}
                                        />
                                      </Box>
                                    )}
                                    {expert?.score.Geral.average_evolution < 0 && (
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        marginLeft="32px"
                                        style={{
                                          backgroundColor: "#FF634714",
                                          padding: "0 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            marginLeft: "6px",
                                            color: "#FF6347",
                                          }}
                                        >
                                          {expert?.score.Geral.average_evolution}
                                        </Text>
                                        <PiChartLineDownBold
                                          style={{
                                            marginLeft: "8px",
                                            color: "#FF6347",
                                          }}
                                        />
                                      </Box>
                                    )}
                                    {expert?.score.Geral.average_evolution ===
                                      "0.00" && (
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        marginLeft="32px"
                                      >
                                        <FaGripLines
                                          style={{
                                            marginLeft: "8px",
                                            color: "#FFA500",
                                          }}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}
                              {selectedServiceCount > 0 && (
                                <Box p={8}>
                                  <Text
                                    fontWeight={400}
                                    fontSize={"16px"}
                                    color={"#B1B1B1"}
                                  ></Text>
                                  {selectedServiceCount === 5 &&
                                    expert?.score.Geral.qtyFeedbacks >= 5 && (
                                      <>
                                        <Text
                                          fontWeight={400}
                                          fontSize={"16px"}
                                          color={"#B1B1B1"}
                                        >
                                          Média do score nos últimos 5 Serviços
                                        </Text>

                                        <Text
                                          fontWeight={700}
                                          fontSize={"40px"}
                                          color={"#FFFFFF"}
                                        ></Text>
                                        <Box display="flex" alignItems="center">
                                          <Text
                                            fontWeight={700}
                                            fontSize={"40px"}
                                            color={"#FFFFFF"}
                                          >
                                            {expert?.score.Geral.five_average_absolute}
                                          </Text>
                                          {expert?.score.Geral.five_evolution >
                                            0 && (
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              marginLeft="16px"
                                              style={{
                                                backgroundColor: "#04D19414",
                                                padding: "0 8px",
                                                borderRadius: "4px",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  marginLeft: "16px",
                                                  color: "#04D194",
                                                }}
                                              >
                                                +
                                                {
                                                  expert?.score.Geral.five_evolution
                                                }
                                              </Text>
                                              <PiChartLineUpBold
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "#04D194",
                                                }}
                                              />
                                            </Box>
                                          )}
                                          {expert?.score.Geral.five_evolution <
                                            0 && (
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              marginLeft="32px"
                                              style={{
                                                backgroundColor: "#FF634714",
                                                padding: "0 8px",
                                                borderRadius: "4px",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  marginLeft: "6px",
                                                  color: "#FF6347",
                                                }}
                                              >
                                                {
                                                  expert?.score.Geral.five_evolution
                                                }
                                              </Text>
                                              <PiChartLineDownBold
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "#FF6347",
                                                }}
                                              />
                                            </Box>
                                          )}
                                          {expert?.score.Geral.five_evolution ===
                                            "0.00" && (
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              marginLeft="32px"
                                            >
                                              <FaGripLines
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "#FFA500",
                                                }}
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      </>
                                    )}
                                  {selectedServiceCount === 5 &&
                                    expert?.score.Geral.qtyFeedbacks < 5 && (
                                      <Box p={8}>
                                        <Text
                                          fontWeight={400}
                                          fontSize={"16px"}
                                          color={"#B1B1B1"}
                                        >
                                          Média do score em todos os Serviços
                                        </Text>
                                        <Text
                                          fontWeight={700}
                                          fontSize={"40px"}
                                          color={"#FFFFFF"}
                                        ></Text>
                                        <Box display="flex" alignItems="center">
                                          <Text
                                            fontWeight={700}
                                            fontSize={"40px"}
                                            color={"#FFFFFF"}
                                          >
                                            {expert.score.Geral.average_absolute}
                                          </Text>
                                          {expert?.score.Geral.average_evolution > 0 && (
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              marginLeft="16px"
                                              style={{
                                                backgroundColor: "#04D19414",
                                                padding: "0 8px",
                                                borderRadius: "4px",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  marginLeft: "16px",
                                                  color: "#04D194",
                                                }}
                                              >
                                                +{expert?.score.Geral.average_evolution}
                                              </Text>
                                              <PiChartLineUpBold
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "#04D194",
                                                }}
                                              />
                                            </Box>
                                          )}
                                          {expert?.score.Geral.average_evolution < 0 && (
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              marginLeft="32px"
                                              style={{
                                                backgroundColor: "#FF634714",
                                                padding: "0 8px",
                                                borderRadius: "4px",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  marginLeft: "6px",
                                                  color: "#FF6347",
                                                }}
                                              >
                                                {expert?.score.Geral.average_evolution}
                                              </Text>
                                              <PiChartLineDownBold
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "#FF6347",
                                                }}
                                              />
                                            </Box>
                                          )}
                                          {expert?.score.Geral.average_evolution === "0.00"
                                             && (
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              marginLeft="32px"
                                            >
                                              <FaGripLines
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "#FFA500",
                                                }}
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                    )}
                                </Box>
                              )}
                            </Box>
                          </GridItem>
                          {categoryScore?.map((index) => (
                            <GridItem key={index.category}>
                              <Box
                                display={"flex"}
                                justifyContent={"left"}
                                alignItems={"center"}
                                backgroundColor={"white"}
                                w={"250px"}
                                h={"140px"}
                                borderRadius={"8px"}
                                border={"1px"}
                                borderColor={"#B1B1B1"}
                                position={"relative"}
                                whiteSpace={"pre-wrap"}
                              >
                                <Box p={8}>
                                  <Text
                                    fontWeight={400}
                                    fontSize={"16px"}
                                    color={"#B1B1B1"}
                                  >
                                    {lineBreak(index.category)}
                                  </Text>
                                  {selectedServiceCount === 0 && (
                                    <Box display={"flex"} alignItems={"center"}>
                                      <Text
                                        fontWeight={700}
                                        fontSize={"40px"}
                                        whiteSpace={"pre-wrap"}
                                      >
                                        {getFormatedValue(
                                          index.average_absolute_category
                                        )}
                                      </Text>
                                      {index?.evolution_category > 0 && (
                                        <Box
                                          display={"flex"}
                                          alignItems={"center"}
                                          marginLeft="16px"
                                          style={{
                                            backgroundColor: "#04D19414",
                                            padding: "0 8px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              marginLeft: "16px",
                                              color: "#04D194",
                                            }}
                                          >
                                            +{index?.evolution_category}
                                          </Text>
                                          <PiChartLineUpBold
                                            style={{
                                              marginLeft: "8px",
                                              color: "#04D194",
                                            }}
                                          />
                                        </Box>
                                      )}
                                      {index?.evolution_category < 0 && (
                                        <Box
                                          display={"flex"}
                                          alignItems={"center"}
                                          marginLeft="16px"
                                          style={{
                                            backgroundColor: "#FF634714",
                                            padding: "0 8px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              marginLeft: "16px",
                                              color: "#FF6347",
                                            }}
                                          >
                                            {index?.evolution_category}
                                          </Text>
                                          <PiChartLineDownBold
                                            style={{
                                              marginLeft: "8px",
                                              color: "#FF6347",
                                            }}
                                          />
                                        </Box>
                                      )}
                                      {index?.evolution_category ===
                                            "0.00" && (
                                        <Box
                                          display={"flex"}
                                          alignItems={"center"}
                                          marginLeft="32px"
                                          style={{
                                            backgroundColor: "#FFF3E0",
                                            padding: "0 8px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <FaGripLines
                                            style={{
                                              marginLeft: "8px",
                                              color: "#FFA500",
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  )}
                                  {selectedServiceCount === 5 &&
                                    expert?.score.Geral.qtyFeedbacks < 5 && (
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                      >
                                        <Text
                                          fontWeight={700}
                                          fontSize={"40px"}
                                          whiteSpace={"pre-wrap"}
                                        >
                                          {getFormatedValue(
                                            index.average_absolute_category
                                          )}
                                        </Text>
                                        {index?.evolution_category > 0 && (
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            marginLeft="16px"
                                            style={{
                                              backgroundColor: "#04D19414",
                                              padding: "0 8px",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                marginLeft: "16px",
                                                color: "#04D194",
                                              }}
                                            >
                                              +{index?.evolution_category}
                                            </Text>
                                            <PiChartLineUpBold
                                              style={{
                                                marginLeft: "8px",
                                                color: "#04D194",
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {index?.evolution_category < 0 && (
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            marginLeft="16px"
                                            style={{
                                              backgroundColor: "#FF634714",
                                              padding: "0 8px",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                marginLeft: "16px",
                                                color: "#FF6347",
                                              }}
                                            >
                                              {index?.evolution_category}
                                            </Text>
                                            <PiChartLineDownBold
                                              style={{
                                                marginLeft: "8px",
                                                color: "#FF6347",
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {index?.evolution_category === "0.00" && (
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            marginLeft="32px"
                                            style={{
                                              backgroundColor: "#FFF3E0",
                                              padding: "0 8px",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            <FaGripLines
                                              style={{
                                                marginLeft: "8px",
                                                color: "#FFA500",
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  {selectedServiceCount === 5 &&
                                    expert?.score.Geral.qtyFeedbacks >= 5 && (
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                      >
                                        <Text
                                          fontWeight={700}
                                          fontSize={"40px"}
                                          whiteSpace={"pre-wrap"}
                                        >
                                          {getFormatedValue(
                                            index?.five_average_absolute_category
                                          )}
                                        </Text>
                                        {index?.five_evolution_category > 0 && (
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            marginLeft="16px"
                                            style={{
                                              backgroundColor: "#04D19414",
                                              padding: "0 8px",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                marginLeft: "16px",
                                                color: "#04D194",
                                              }}
                                            >
                                              +{index?.five_evolution_category}
                                            </Text>
                                            <PiChartLineUpBold
                                              style={{
                                                marginLeft: "8px",
                                                color: "#04D194",
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {index?.five_evolution_category < 0 && (
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            marginLeft="32px"
                                            style={{
                                              backgroundColor: "#FF634714",
                                              padding: "0 8px",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                marginLeft: "6px",
                                                color: "#FF6347",
                                              }}
                                            >
                                              {index?.five_evolution_category}
                                            </Text>
                                            <PiChartLineDownBold
                                              style={{
                                                marginLeft: "8px",
                                                color: "#FF6347",
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {index?.five_evolution_category === "0.00" && (
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            marginLeft="32px"
                                          >
                                            <FaGripLines
                                              style={{
                                                marginLeft: "8px",
                                                color: "#FFA500",
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                </Box>

                                {index.category !==
                                  "Qualidade Revisão por Pares" && (
                                  <InfoIcon category={index.category} />
                                )}
                              </Box>
                            </GridItem>
                          ))}
                        </Grid>
                      </SimpleGrid>
                      {/*{selectedServiceCount > 0 && (
                        <SimpleGrid columns={3}>
                          <GridItem ml={7}>
                            <Box
                              display={"flex"}
                              backgroundColor={"#000000"}
                              w={"250px"}
                              h={"140px"}
                              borderRadius={"8px"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Box p={8}>
                                <Text
                                  fontWeight={10}
                                  fontSize={"12px"}
                                  color={"#FFFFFF"}
                                >
                                  {selectedServiceCount === 3 && (
                                    <>
                                      <Text
                                        fontWeight={400}
                                        fontSize={"16px"}
                                        color={"#B1B1B1"}
                                      >
                                        Média Absoluta
                                      </Text>

                                      <Text
                                        fontWeight={700}
                                        fontSize={"40px"}
                                        color={"#FFFFFF"}
                                      >
                                        {expert?.threeAVGAbsolute}
                                      </Text>
                                    </>
                                  )}
                                  {selectedServiceCount === 5 && (
                                    <>
                                      <Text
                                        fontWeight={400}
                                        fontSize={"16px"}
                                        color={"#B1B1B1"}
                                      >
                                        Score
                                      </Text>

                                      <Text
                                        fontWeight={700}
                                        fontSize={"40px"}
                                        color={"#FFFFFF"}
                                      >
                                        {expert?.fiveAVGAbsolute}
                                      </Text>
                                    </>
                                  )}
                                  {selectedServiceCount === 10 && (
                                    <>
                                      <Text
                                        fontWeight={400}
                                        fontSize={"16px"}
                                        color={"#B1B1B1"}
                                      >
                                        Score
                                      </Text>

                                      <Text
                                        fontWeight={700}
                                        fontSize={"40px"}
                                        color={"#FFFFFF"}
                                      >
                                        {expert?.tenAVGAbsolute}
                                      </Text>
                                    </>
                                  )}
                                </Text>
                              </Box>
                            </Box>
                          </GridItem>
                        </SimpleGrid>
                                  )}*/}
                    </Box>
                    {/*<Box marginLeft={7}>
                      {categoryScore != null ? (
                        <>
                          <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                            {categoryScore?.map((index) => (
                              <GridItem>
                                <Box 
                                  key={index.category}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  backgroundColor={"white"}
                                  w={"250px"}
                                  h={"140px"}
                                  borderRadius={"8px"}
                                  border={"1px"}
                                  borderColor={"#B1B1B1"}
                                  position={"relative"}
                                  whiteSpace={"pre-wrap"} 
                                >
                                  
                                  <Box p={8}>
                                    
                                    <Text
                                      fontWeight={400}
                                      fontSize={"16px"}
                                      color={"#B1B1B1"}
                                    >
                                      {lineBreak(index.category)}
                                    </Text>
                                    {selectedServiceCount === 0 && (
                                      <Text fontWeight={400} fontSize={"40px"} whiteSpace={"pre-wrap"}>
                                      {index.averageCategory?.absolute_value}
                                       
                                    </Text>                                
                              </GridItem>
                            ))}
                          </Grid>
                        </>
                      ) : (
                        " "
                      )}
                      </Box>*/}
                  </>
                ) : (
                  <Box p={8}> Não há informações de pontuação</Box>
                )}

                {/*
                                <Box marginTop={"25px"} marginBottom={"50px"}>
                                    <TableContainer>
                                        <Table fontSize={"12px"}>
                                            <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                                                <Tr>
                                                    <Th>ID </Th>
                                                    <Th>Titulo </Th>
                                                    <Th>Função </Th>
                                                    <Th>Modificações </Th>
                                                    <Th>Consistência </Th>
                                                    <Th>Pontuação </Th>
                                                    <Th>Abreviação </Th>
                                                    <Th>Conexão </Th>
                                                    <Th>Média Final </Th>
                                                </Tr>
                                            </Thead>
                                        </Table>
                                    </TableContainer>
                                </Box> 
                               */}
              </Box>
            </Flex>
            {totalScore != 0 ? (
              <Flex
                backgroundColor={"white"}
                borderRadius={"8px 8px"}
                boxShadow={"0px 8px 16px -10px"}
                marginTop={"10px"}
                overflowX={"auto"}
              >
                <Tabs>
                  <TabPanels>
                    <TabPanel>
                      <Box>
                        <Heading as="h2" mb={4}>
                          <Text fontWeight={400} fontSize={"20px"} ml={7}>
                            Serviços que você participou
                          </Text>
                        </Heading>
                        <Box
                          backgroundColor={"white"}
                          mt={5}
                          boxShadow={"0px 8px 16px -10px"}
                          borderRadius={"8px"}
                          marginBottom={5}
                          overflowX={"auto"}
                        >
                          <TableContainer>
                            <Table fontSize={"12px"} width={"100%"}>
                              <Thead
                                css={{
                                  fontFamily: "Poppins",
                                  fontSize: "12px",
                                }}
                              >
                                <Tr>
                                  <Th>ID</Th>
                                  <Th>Título</Th>
                                  <Th>Função</Th>
                                  <Th>Pontuação</Th>
                                  <Th>Números</Th>
                                  <Th>Consistência</Th>
                                  <Th>Abreviações</Th>
                                  <Th>
                                    Plain English
                                    <Text
                                      as="span"
                                      fontSize="8px"
                                      display="block"
                                    >
                                      (Reorganização de sentenças, concôrdancia
                                      e conexão)
                                    </Text>
                                  </Th>
                                  <Th>
                                    Plain English
                                    <Text
                                      as="span"
                                      fontSize="8px"
                                      display="block"
                                    >
                                      (Writing zeros e nominalização)
                                    </Text>
                                  </Th>
                                  <Th>Capitalização</Th>
                                  <Th>Termos da área</Th>
                                  <Th>Média Final</Th>
                                </Tr>
                              </Thead>
                              <Tbody color={"#888686"} fontWeight={"300"}>
                                {Object.keys(expert.score.feedbacksData).map(
                                  (orderId) => {
                                    const data = expert.score.feedbacksData[orderId];
                                    return (
                                      <Tr key={orderId}>
                                        <Td>{orderId}</Td>
                                        <Td>
                                          <button
                                            onClick={() =>
                                              handleEditService(orderId)
                                            }
                                            style={{
                                              all: "unset",
                                              cursor: "pointer",
                                              fontWeight: "bold",
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {limitToTwoWords(data.title)}
                                          </button>
                                        </Td>
                                        <Td>{data.funcao}</Td>
                                        <Td>
                                          {data.category_1 ||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_2 ||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_3 ||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_4 ||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_5 ||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_6||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_7 ||
                                            "-"}
                                        </Td>
                                        <Td>
                                          {data.category_8 ||
                                            "-"}
                                        </Td>
                                        <Td>{data.average?.toFixed(2)}</Td>
                                      </Tr>
                                    );
                                  }
                                )}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            ) : (
              <> </>
            )}
          </Container>
        </>
      )}
    </>
  );
}
